// Start Rails and necessary modules
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "channels";
import "custom/subscriptions";
import "custom/trix-autolinks";
import "custom/trix-editor-overrides";


// Import other dependencies
import flatpickr from "flatpickr"
import "controllers"

// Trix setup
import Trix from 'trix'
require("trix")
require("@rails/actiontext")
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false

// SlimSelect setup
import SlimSelect from 'slim-select'

